<template>
  <div class="tables">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light">Nom : </span>
          <span style="font-size: 22px">{{ composant.name }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des applications supportées :</h4>
        <hr />
      </div>
    </div>
    <b-table
      :fields="appFields"
      :items="tableItems"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <!-- <template v-slot:cell(taux)="data">
        <b-progress :max="100" height="1rem">
          <b-progress-bar :value="data.value">
            <span> {{ data.value }}% </span>
          </b-progress-bar>
        </b-progress>
      </template> -->
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template v-slot:cell(capaciteSis)="data">
        <b-badge
          style="
            cursor: pointer;
            background-color: #adb5bd;
            border-color: #adb5bd;
          "
          pill
          >{{ data.value.length || 0 }}</b-badge
        >
      </template>

      <template v-slot:cell(status)="data">
        <span>
          {{ data.value }}
        </span>
      </template>
      <template v-slot:cell(ref)="data">
        <span> SA-{{ data.item.id }} </span>
      </template>
      <template v-slot:cell(po)="data">
        <TableResponsable :responsable="data.value" />
      </template>
      <template v-slot:cell(taux)="data">
        <b-progress
          max="100"
          show-progress
          height="15px"
          style="background-color: #cacccd !important"
        >
          <b-progress-bar
            :value="data.value || 0"
            :label="`${data.value || 0}%`"
            :style="`background-color: ${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            }`"
          ></b-progress-bar>
        </b-progress>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable";

export default {
  components: {
    TableResponsable,
  },
  props: {
    composant: Object,
  },
  data: () => ({
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    tableItems: [],
    appFields: [
      { key: "ref", label: "Ref SA", sortable: true },
      { key: "name", label: "Libellé", sortable: true },
      { key: "capaciteSis", label: "Capacité SI", sortable: true },
      { key: "po", label: "PO", sortable: true },
      { key: "status", label: "Statut", sortable: true },
      { key: "taux", label: "Taux de remplissage", sortable: true },
    ],
  }),
  created() {
    this.$store
      .dispatch("composant/fetchComposantApplications", this.composant.id)
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("composant", ["APPLICATIONS"]), //get all status
    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    APPLICATIONS() {
      this.tableItems = this.APPLICATIONS.applications;
    },
  },
};
</script>

<style></style>
